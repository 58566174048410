export const getArticleFilterOptions = (data) =>
  data.map((article) => {
    const { reportName, reportDescription } = article
    return {
      title: `${reportName} - ${reportDescription}`,
      value: article,
    }
  })

export const getContractPartnerFilterOptions = (data) =>
  data.map((partner) => {
    const { publisherName, publisherNumber } = partner
    return {
      title: `${publisherNumber}, ${publisherName}`,
      value: partner,
    }
  })

export const getReceiptTypeFilterOptions = (data) =>
  data.map((reportCategory) => ({
    title: reportCategory,
    value: reportCategory,
  }))
